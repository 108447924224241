import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    isOpenSidebar: false,
    isOpenNotification: false,
    messageCount: 0,
    number: "",
  },
});
