import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue } from 'bootstrap-vue'
import VueYoutube from 'vue-youtube'
import axios from 'axios'
import Scrollspy from 'vue2-scrollspy';
var VueScrollTo = require('vue-scrollto');
import i18n from './lang/index.js'
Vue.config.productionTip = false
import 'vue-select/dist/vue-select.css';
import "@/assets/scss/style.scss";
import "@/assets/scss/responsive.scss";
import "@/assets/css/materialdesignicons.min.css";
import 'vue2-datepicker/index.css';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import VueMask from 'v-mask'
import { VueMaskDirective } from 'v-mask'
import VueSlider from 'vue-slider-component'
import VueCurrencyFilter from 'vue-currency-filter'
import 'vue-slider-component/theme/default.css'
import ApiService from "@/services/api.service";
import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);
Vue.component('VueSlider', VueSlider)
Vue.use(VueYoutube)
Vue.directive('mask', VueMaskDirective);
Vue.use(VueMask);
Vue.use(Scrollspy);
Vue.use(VueScrollTo)
Vue.use(VueToast,{
  position: 'top'
});
ApiService.mount401Interceptor();
// Install BootstrapVue
Vue.use(BootstrapVue)
Vue.prototype.$clientUrl = window.location.href.split("?")[0];

axios.defaults.baseURL = "http://cultureadm-api.apptest.uz/"; 
if (window.location.href.indexOf("http://sportmy.apptest.uz") > -1) {
  axios.defaults.baseURL = "http://sportmy-api.apptest.uz";
}
if (window.location.href.indexOf("http://my.sport.uz") > -1) {
  axios.defaults.baseURL = "http://my-api.sport.uz";
}
if (window.location.href.indexOf("https://my.sport.uz") > -1) {
  axios.defaults.baseURL = "https://my-api.sport.uz";
}

const token = localStorage.getItem("auth_token");
if (token) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

localStorage.getItem('locale') === null ? localStorage.setItem('locale','uz_latn') : ''
axios.interceptors.request.use(request => requestInterceptor(request))
const requestInterceptor = (request) => {
	request.withCredentials = true
	return request
}
Vue.use(VueCurrencyFilter, {
	symbol: 'c',
	thousandsSeparator: ' ',
	fractionCount: 0,
	fractionSeparator: ',',
	symbolPosition: 'back',
	symbolSpacing: true
});
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
