import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name : 'Home',
    component: () => import('../views/index1.vue')
  },
  {
    path : '/register',
    name : "Register",
    component : () => import('../views/register.vue')
  },
  {
    path : '/cabinet',
    name : "Cabinet",
    component : () => import('../views/cabinet.vue')
  },
  {
    path : '/homepage',
    name : "HomePage",
    component : () => import('../views/index1.vue')
  },
  {
    path : '/countryreward',
    name : "CountryReward",
    component : () => import('../views/countryreward.vue')
  },
  {
    path : '/rewardwinners',
    name : "RewardWinners",
    component : () => import('../views/rewardwinners.vue')
  },
  {
    path : '/creators',
    name : "Creators",
    component : () => import('../views/creators/index.vue')
  },
  {
    path : '/creators/view/id=:id',
    name : "CreatorsView",
    component : () => import('../views/creators/view.vue')
  },
  {
    path: '/index-1',
    name: 'Index-1',
    component: () => import('../views/index1.vue')
  },
  {
    path: '/index-2',
    name: 'Index-2',
    component: () => import('../views/index2.vue')
  },
  {
    path: '/index-3',
    name: 'Index-3',
    component: () => import('../views/index3.vue')
  },
  {
    path: '/index-4',
    name: 'Index-4',
    component: () => import('../views/index4.vue')
  },
  {
    path: '/index-5',
    name: 'Index-5',
    component: () => import('../views/index5.vue')
  },
  {
    path: '/index-6',
    name: 'Index-6',
    component: () => import('../views/index6.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/pages/AccountOneId.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach((to, from, next) => {
//   if (to.path !== "/login" && !to.meta.redirectIfLoggedIn) {
//     if (localStorage.getItem("user_info")) {
//       next();
//     } else {
//       next("login");
//     }
//   } else {
//     next();
//   }
// });

export default router
